import './pt.scss';

export default {
	model: {
		prop1: {
			type: 'generic-list'
		}
	},
	listeners: {
		mouseenter: true,
		mouseleave: true,
		click: true,
		contextmenu: true
	}
};
